const logger = () => {
  return next => action => {
    if (process.env.NODE_ENV === "development") {
      console.info("[Redux] Action triggered: " + action.type);
    }

    return next(action);
  };
};

export default logger;
